import FormBoxBuilder from "./formbuilder";

function App() {
  return (
    <div className="App">
      <FormBoxBuilder />
    </div>
  );
}

export default App;
